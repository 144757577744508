.iteminfo-name {
    overflow: hidden;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: black;
    font-size: 18px;
}
.iteminfo-sex {
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    text-align: center;
    color: #71757A;
}
.iteminfo-price {
    overflow: hidden;
    margin-top: 5px;
    text-align: center;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: black;
    font-size: 20px;
    font-weight: 500;
}
.iteminfo-price-old-mini,
.iteminfo-price-old {
    color: rgba(0, 0, 0, 0.51);
    font-size: 16px;
    position: relative;
    margin-right: 10px;
    font-weight: normal;
}
.iteminfo-price-old-mini {
    font-size: 80%;
    margin-right: 0;
    margin-left: 6px;
}
.iteminfo-price-old-mini:after,
.iteminfo-price-old:after {
    content: '';
    height: 2px;
    background-color: rgba(0, 0, 0, 0.51);
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
}
.iteminfo-price-old-mini:after {
    height: 1px;
}
.iteminfo-description {
    margin-top: 5px;
    padding-top: 12px;
    color: black;
    font-size: 15px;
}
.iteminfo-info {
    margin-top: 12px;
    color: #959698;
    font-size: 13px;
    line-height: 1.3;
}
.productInformation {
    white-space: normal;
}
