.cart-item-image {
    display: inline-block;
    width: 90px;
    height: 90px;
    border-radius: 7px;
}
.cart {
    overflow-x: hidden;
    padding-bottom: 44px;
}
.cart-item-name {
    overflow: hidden;
    max-width: 96%;
    margin-left: 3px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #2c2d2d;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 16px;
}
.cart-item-description {
    overflow: hidden;
    max-width: 96%;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #959698;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 14.5px;
    line-height: 1.4;
}
.cart-item-price-current {
    display: inline-block;
    overflow: hidden;
    max-width: 96%;
    margin-top: 5px;
    margin-left: 3px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    color: #2c2d2d;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 14.5px;
    font-weight: 500;
}
.cart-item-info {
    position: absolute;
    display: inline-block;
    width: calc(100% - 160px);
    margin-top: 4px;
    margin-left: 10px;
}
.cart-item-options {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-family: system-ui ,'Roboto', sans-serif;
    line-height: 1.4;
}
.cart-select-container {
    position: relative;
    display: inline-block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    width: 98px;
    margin-right: 6px;
    border: 0;
}
.cart-select {
    width: 100%;
    color: #528bcc;
    border: 0;
    background-color: white;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
}
.cart-select-option {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}
.cart-sum {
    display: inline-block;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: #9b9ea1;
    border: 1px solid #e8e8e8;
    background-color: #fbfbfb;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.4;
}
.cart-sum-value {
    display: inline-block;
    color: black;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 400;
}
.cart-group-title {
    padding: 10px;
    color: #000;
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
}
.cart-textarea {
    position: relative;
    width: calc(100% - 12px);
    height: 30px;
    margin-top: 7px;
    padding-left: 12px;
    resize: none;
    color: black;
    border: 1px solid #e1e1e1;
    border-radius: 7px; /* Уголки */
    background-color: #f4f4f4;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 14px;
    line-height: 30px;
}
.checkbox {
    display: inline-block;
    border-radius: 7px;
    background-color: white;
}
.checkbox-text-container {
    display: inline-block;
    height: 25px;
}
.checkbox-text {
    margin-left: 10px;
    color: #2c2d2e;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 15px;
}
.checkbox-container {
    height: 25px;
    margin-top: 20px;
}
.checkbox-wrapper {
    margin-top: 5px;
}
.cart .FormLayoutNew--ios {
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
}
.cart .FormLayoutNew--android {
    padding-top: 10px;
    padding-right: 0;
    padding-left: 0;
}
.hint {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: #71757a;
    font-family: system-ui ,'Roboto', sans-serif;
    font-size: 18px;
    line-height: 1.5;
}
.Input--s-verified .Input__el {
    border-color: green;
}
.Input--s-verified .Input-underline::before {
    background-color: green;
}
.Input--s-error .Input__el {
    border-color: red;
}
.Input--s-error .Input-underline::before {
    background-color: red;
}
.selectnew .Cell__children {
    white-space: normal !important;
}
.devItems .Select__container,
.pickupItems .Select__container{
     height: auto !important;
    min-height: 44px !important;
}
.devItems .Select__title,
.pickupItems .Select__title{
    white-space: normal !important;
}